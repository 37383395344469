import React from 'react';
import { useSetState } from 'react-use';
import { IoIosArrowDropdown } from 'react-icons/io';
import { FaRss } from 'react-icons/fa';
import { navigate } from 'gatsby';

import Button from 'molecules/Button';
import CategoryLinks from 'organisms/CategoryLinks';
import Container from 'molecules/Container';
import Hero from 'organisms/Hero';
import PostSnippet from 'molecules/PostSnippet';
import Select from 'molecules/Select';
import { client } from 'lib/sanity';
import { postFields } from 'data/fields';

import '../Blog.scss';

import blogBanner from 'static/images/blog-banner.jpg';
import categories from 'data/globals/categories';

categories.sort( ( a, b ) => {
  if( a.name > b.name ) {
    return 1;
  }
  else {
    return -1;
  }
} );
const categoryOptions = [
  <Select.Option value="select">Filter posts by category</Select.Option>
];
for( let c of categories ) {
  categoryOptions.push( <Select.Option value={ typeof c.slug === 'object' ? c.slug.current : c.slug }>{ c.name }</Select.Option> );
}

const CategoryArchive = ( { slug: categorySlug } ) => {

  const [ s, setState ] = useSetState( {
    //categoryShowing: 'all',
    postsShowing: [],
    noMorePosts: false,
  } );

  // Pagi stuff.
  //const currentPagi = !! s.postsShowing.length ? s.postsShowing.length + 1 : 0;
  const currentPagi = s.postsShowing.length;
  const range = `${currentPagi}..${currentPagi + 7}`;

  // Query.
  // const categoryQuery = `
  //   *[ _type == "post" && references(
  //     *[ _type == "category" && slug.current == "${categorySlug}" ]._id
  //   ) ] | order( datePublished desc ) {
  //     ${postFields()}
  //   }[ ${range} ]
  // `;

  /* ------ = Functions. = --------------------------------------------------------------------- */

  /**
   * Load more posts.
   *
   * @param {bool}  clearead
   */
  const loadMorePosts = async cleared => {

    const categoryQuery = `
      *[ _type == "post" && references(
        *[ _type == "category" && slug.current == "${categorySlug}" ]._id
      ) ] | order( datePublished desc ) {
        ${postFields()}
      }[ ${ !! cleared ? '0..7' : range } ]
    `;

    const response = await client.fetch( categoryQuery );

    if( !! response.length  ) {

      const updatedPosts = !! cleared ? response : [ ...s.postsShowing, ...response ];


      setState( {
        postsShowing: updatedPosts,
        noMorePosts: !! cleared && response.length < 7 ? true : false,
      } );

    }
    else {
      setState( {
        noMorePosts: true,
      } );
    }

  }

  /**
   * Handle page change.
   */
  const handlePageChange = () => {

    setState( {
      postsShowing: [],
    } );

    loadMorePosts( true );

  }

  /* ------ = Lifecycle. = --------------------------------------------------------------------- */

  React.useEffect( () => {

    loadMorePosts();

  }, [] );

  React.useEffect( () => {
    handlePageChange();
  }, [ categorySlug ] );

  // React.useEffect( () => {
  //
  //   const categoryQuery = s.categoryShowing !== 'all' ? `&& references( "${s.categoryShowing}" )` : '';
  //   const query = `*[ _type == "post" ${categoryQuery} ] | order( datePublished desc ) {
  //     ${postFields()}
  //   }[ 7 ]`;
  //   client.fetch( query ).then( response => {
  //
  //     console.log( response );
  //
  //     if( !! response && response.length ) {
  //
  //       const updatedPosts = [ ...response ];
  //       setState( {
  //         postsShowing: updatedPosts
  //       } );
  //     }
  //
  //   } );
  //
  //
  //
  // }, [ s.categoryShowing ] );

  /* ------ = Output. = --------------------------------------------------------------------- */

  return(

    <div className="Blog">

      <Hero
      size="sm"
      data={ {
        bg: {
          src: blogBanner
        }
      } }
      />


      <Container>

        { /* Top */ }
        <div className="Blog__top">
          <h1 className="Blog__top-hding">Pastor's Blog</h1>
          <div className="Blog__top-filter d-md-none">
            <Select
            value={ categorySlug }
            onChange={ value => navigate( `/blog/category/${value}` ) }
            >
              { categoryOptions }
            </Select>
          </div>
        </div>

        { /* Body */ }
        <div className="Blog__body row">

          <div className="Blog__posts col-md-8">
            { !! s.postsShowing && s.postsShowing.map( post => (

              <article
              key={ post._id }
              >
                <PostSnippet post={ post } />
              </article>

            ) ) }

            { ! s.noMorePosts &&

              <div className="Blog__posts-loadmore">
                <Button
                color="gold"
                onClick={ () => loadMorePosts() }
                >
                  Load more posts
                  <IoIosArrowDropdown />
                </Button>
              </div>


            }



          </div>

          <div className="Blog__body-side col-md-3 offset-1">
            <div className="Blog__side d-none d-md-block">
              <CategoryLinks />
            </div>
          </div>

        </div>



      </Container>


    </div>


  );
}
export default CategoryArchive;
